import * as React from "react"


import { useEffect, useRef, useState } from "react"
import { useForm } from "react-hook-form"

import * as styles from "../../../../scss/loginPage.module.scss"
import * as formStyles from "../../../../scss/form.module.scss"
import * as btnStyles from "../../../../components/buttons/buttons.module.scss"
import axios from "axios"
import { Link, navigate } from "@reach/router"
import Logo from "../../../../images/logo.svg"
import { BsEye, BsEyeSlash } from "react-icons/all";


const ResetPasswordPage = (props) => {
    const shapeFirst = useRef(null)
    const shapeSecond = useRef(null)
    const shapeThird = useRef(null)
    const [showSuccess, setShowSuccess] = useState(false);
    const [showInvCred, setShowInvCred] = useState(false);
    const { register, handleSubmit, watch, errors } = useForm()
    const [password, setPassword] = useState('');
    const [validServerRequest, setValidServerRequest] = useState(true);
    const [showPass, setShowPass] = useState(true);

    console.log(props.params);

    const doSubmit = ({ password }) => {

        let valid = true;

        if (props.params === undefined) valid = false;
        if (props.params.email === undefined) valid = false;
        if (props.params.key === undefined) valid = false;
        if (props.params === null) valid = false;
        if (props.params.email === null) valid = false;
        if (props.params.key === null) valid = false;

        if (!valid) {

            setShowInvCred(true);

            return false;
        }

        let config = {
            method: 'post',
            url: 'https://detoxme.at/wp/wp-json/siegfried/v1/restorepassword',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                password,
                email: props.params.email,
                key: props.params.key
            }
        };

        axios(config)
            .then((response) => {
                console.log("The axios login response", response);
                if (response.data.c == 200) {
                    setShowSuccess(true);
                }
                else {
                    setShowInvCred(true);
                }
            }
            )
            .catch(function (error) {
                console.log(error);
            });

    }

    return (

        <div className={styles.loginPage}>
            <div className={styles.shapeFirst}></div>
            <div className={styles.shapeSecond}></div>
            <div className="container">
                <Link to="/">
                    <Logo className={styles.logo} />
                </Link>
                <div className={styles.row}>
                    <div className={styles.content}>
                        <h1>Einsteigen & Entgiften</h1>
                        <p>
                            Mithilfe meines Online Detox-Kurses erfährst du die besten
                            Praktiken, wertvolle Tipps und Schritt-für-Schritt Anleitungen, um
                            deinen Körper effektiv von Giftstoffen zu befreien.
                        </p>
                        <p>
                            So ist es möglich, mit Genuss und Freude zu entgiften, sich von
                            körperlichen Beschwerden zu befreien und seine Gesundheit auch in
                            einer belastenden Umwelt zu erhalten.
                        </p>
                    </div>
                    <div className={styles.form}>
                        <h2>Passwort ändern</h2>

                        <form className={formStyles.form} onSubmit={handleSubmit(doSubmit)}>

                            <p style={{ marginBottom: " 5px" }}>Passwort</p>
                            <div className={formStyles.passwordInput}>
                                <input
                                    type={showPass ? "password" : "text"}
                                    name="password"
                                    className={formStyles.input}
                                    onChange={e => setPassword(e.target.value)}
                                    value={password}
                                    placeholder="************"
                                    ref={register({
                                        required: true,
                                        // pattern: /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/i,
                                    })}
                                />
                                <button type="button" onClick={() => { setShowPass(!showPass) }}>
                                    {showPass ? <BsEye /> : <BsEyeSlash />}
                                </button>
                            </div>

                            {errors.password && (
                                <p class={formStyles.inputError} style={{ marginTop: "5px", marginbottom: "5px" }}>
                                    Passwort ist erforderlich und mindestens acht Zeichen, mindestens
                                    ein Großbuchstabe, ein Kleinbuchstabe und eine Zahl
                                </p>
                            )}
                            <input style={{ marginTop: " 25px" }}
                                type="submit"
                                className={`${btnStyles.button} ${btnStyles.buttonBorder}`}
                                value="Passwort zurücksetzen"
                            />

                            {showInvCred && (
                                <p class={formStyles.inputError} style={{ marginTop: "5px", marginbottom: "5px" }}>
                                    Etwas ist schief gelaufen. Bitte versuche es erneut.
                                </p>
                            )}
                            {showSuccess && (
                                <p class={formStyles.inputError} style={{ marginTop: "5px", marginbottom: "5px", color: 'green' }}>
                                    Dein Passwort wurde erfolgreich zurückgesetzt. Du kannst dich jetzt wieder anmelden.
                                </p>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordPage